<template>
	<div>
		<v-row>
			<v-col cols="6">
				<s-user 
					@input="changeUserSelected" 
					:isCrud="isCrud"
				/>
			</v-col>
			<v-col>
				<s-toolbar
					label="Reportes"
					color="grey"
					dark
					searchInput
					@textSearch="textSearch($event)" 
				></s-toolbar>
				<v-data-table
					:headers="headerQuerys"
					v-model="itemsQuerysSelected"
					dense
					:items="itemsQuerys"
					show-select
					item-key="QryID"
					@item-selected="clickReportSelected($event)"
				>

				</v-data-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import sUser from "@/views/Security/UserOption/User.vue";
	import _sConfiguration from "@/services/QueryManager/QryConfigurationService";
	import _sConfigurationPermissionUser from "@/services/QueryManager/QryConfigurationPermissionUserService.js";

	export default {
		components: {
			sUser,
		},

		data() {
			return {
				isCrud: false,
				itemsQuerys : [],
				headerQuerys : [
					{text : 'ID', value : 'QryID' },
					{text : 'Opción', value : 'OptName' },
					{text : 'Reporte', value : 'QryNameReport' },
					{text : 'Accion', value : 'action' },
				],
				filter: {},
				reportPermission : {},
				userSelected :{},
				itemsQuerysSelected : [],
				UsrID : 0
			}
		},
		

		methods: {
			textSearch(text){
                this.filter.Search = text;
                this.loadChanges();
            },
			loadChanges(){
                this.getListQuerys();
            },
			changeUserSelected(user) {
				if(user.UsrID != undefined)
				{
					this.UsrID = user.UsrID
				}
				this.userSelected = user;
				this.getListQuerysByUser();
				this.getListQuerys();
			},

			getListQuerys()
			{
				_sConfiguration
				.list(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsQuerys = resp.data;
						console.log('quersss', this.itemsQuerys);
					}
				})
			},

			clickReportSelected(report)
			{
				this.reportPermission.QryID = report.item.QryID;
				this.reportPermission.OptID = report.item.OptID;
				this.reportPermission.UsrID = this.userSelected[0].UsrID;
				this.reportPermission.SecStatus = 1;
				this.reportPermission.UsrCreateID = 1;
				this.reportPermission.UsrUpdateID = 1;

				_sConfigurationPermissionUser
				.save(this.reportPermission, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Guardado Correctamente", "success");
						return;
					}
				})
			},

			getListQuerysByUser()
			{
				if(this.userSelected[0] != undefined)
				{
					_sConfigurationPermissionUser
					.list({
						UsrID : this.userSelected[0].UsrID
					}, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.itemsQuerysSelected = resp.data;
						}
					})
				}
			}
		},

		created () {
			this.getListQuerys();
		},
	}
</script>
